@import "../../../shared-styles/index";



.splash-screen {
    background-image: url("../assets/splash-screen/arrows-bright-compressed.jpg");
    min-height: 100vh;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__logo {
        background-image: url("../assets/logo-wordmark-white.svg");
        min-width: 44rem;
        min-height: 12.8rem;
        background-repeat: no-repeat;
        margin-top: 21.3rem;
        padding-right: 1rem;
    }
} 

/* REM override to 10px */
html {
	font-size: 62.5%;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
	margin: 0;
	padding: 0;
	font-size: 1.4rem;
	font-family: 'Source Sans Pro', sans-serif !important;
}
* {
	box-sizing: border-box;
}
input {
	font-size: 1.7rem !important;
}

label {
	font-size: 1.7rem !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
	-webkit-transition-delay: 9999s;
}
input:-webkit-autofill {
    -webkit-box-shadow:0 0 0 50px white inset;
}

input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px white inset;
} 
#chart svg {
	overflow: visible;
}
.fade-in {
	opacity: 1;
	height: auto;
	-webkit-animation: fadeIn 1s;
  }
  
  .fade-out {
	transition: opacity 4s ease-out;
	opacity: 0;
	height: 0;
	overflow: hidden;
	-webkit-animation: fadeOut 1s;
  }
  
  @-webkit-keyframes fadeIn {
	from {
	  opacity: 0;
	}
	to {
	  opacity: 1;
	}
  }
  
  @keyframes fadeIn {
	from {
	  opacity: 0;
	}
	to {
	  opacity: 1;
	}
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
  }
  /* Firefox */
  
  input[type=number] {
	-moz-appearance: textfield;
}

// Fixes for material theme


// Fix for long labels bug
// https://github.com/mui-org/material-ui/issues/12255
.MuiFormLabel-root {
	overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    right: 22px; 
}
.MuiFormHelperText-contained {
	margin: 0 !important; 
}
.MuiFormControl-root {
	margin-bottom: 12px;
}
.MuiSelect-selectMenu {
	white-space: normal !important;
	display: flex !important;
	align-items: center;
	justify-content: space-between;
}
@media screen and (max-device-width: 640px) {

	.MuiTableCell-root {
		padding: 8px;
	}
}

@media screen and (max-device-width: 767px) {
	.MuiGrid-spacing-xs-2 > .MuiGrid-item {
		padding-bottom: 8px;
	}
}

.selectStyle {
	.MuiSelect-selectMenu {
		padding: 0 12px 5px 0!important;
		min-height: 48px;
		display: flex;
		align-items: center;
	}
}

.rdsu-image img {
	max-width: 100%;
	max-height: 100%;

}
.react-dropzone-s3-uploader {
	z-index: 11;
}
.fileUploaderWrapper {
	display: flex;
    position: relative;
    flex-direction: column;
	align-items: center;
}
.rdsu-filename {
	word-break: break-all;
}

*:focus {
	outline: none;
}
.MuiCheckbox-root.Mui-disabled + .MuiFormControlLabel-label {
	color: rgba(0, 0, 0, 0.38);
}

.Mui-disabled {
	cursor: default;
}


.zoom-overlay-open .mainBlock, 
.zoom-overlay-open .menuButton {
	z-index: auto !important;
}

.zoom-img, .zoom-img-wrap {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	margin: 0 auto !important;
}

img.zoom-img
{
	border-radius: inherit !important;
}

.zoom-overlay-open div:not(.zoom-img-wrap) {
	pointer-events: none
}

.MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(12px, 8px) scale(0.85) !important;
}
.bodyImitateModal  {
	.menuButton {
		display: none !important;
	}

}
@media screen and (max-device-width: 640px) {
	.imitateModalOnMobileOpened, 
	.imitateModalOnMobileClosed {
		display: none;
	}
}
.bodyImitateModal {

	.imitateModalOnMobileClosed ,
	.imitateModalOnMobileOpened {
		display: block;
		padding-top: 28px;
		@media screen and (max-device-width: 640px) {
			display: block;
			position: fixed;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: #fff;
			overflow: auto;
			padding: 50px 16px !important;
		}
		.closeButtonWrapper {
			position: fixed;
			top: 0;
			left: 0;
			justify-content: flex-end;
			background: #fff;
			width: 100%;
			z-index: 4;
		}
		.closeButton{
			background: #fff;
			display: block;		
			border: none;
			padding: 6px;
			.MuiSvgIcon-root {
				font-size: 24px;
			}
		}
	}
	
}


@media screen and (min-device-width: 640px) and (min-width: 640px){
	.imitateModalOnMobileOpened, 
	.imitateModalOnMobileClosed {
		display: block !important;
	}
}


.MuiInputBase-input.MuiFilledInput-input {
	height: 20px;
}
textarea.MuiInputBase-input.MuiFilledInput-input  {
	height: auto;
}
.MuiInputBase-root.MuiFilledInput-root {
	padding-left: 0 !important;
}
.MuiInputBase-input {
	padding-left: 10px;
}
.MuiInputBase-input.MuiFilledInput-input {
	padding-left: 10px;
}


.MuiList-root .Mui-selected .MuiSvgIcon-root {
	display: none;
}
.admin-modal {
    min-width: 612px;
	padding: 48px 100px;
	background: var(--palette-secondary-main);
	&.admin-modal-wide {
		min-width: 1048px;
	}
}
